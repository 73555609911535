"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createAuthorEvent = createAuthorEvent;
exports.createBaseEvent = createBaseEvent;
exports.createClientEvent = createClientEvent;
exports.createLogger = createLogger;
exports.processQueue = processQueue;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _utils = require("../utils");

var _network = require("./network");

var _base = _interopRequireDefault(require("../../event-schema/json/base.json"));

var _schemaValidators = require("./schemaValidators");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var BaseSchema = _base.default.nested.system1.nested.base.nested.v1.nested;
var globalPayload = {
  pageUniq: (0, _utils.getUniqueId)()
};

if (global.document) {
  globalPayload.referrer = document.referrer || '';
}

if (global.location) {
  globalPayload.url = global.location.href || '';
}
/**
 * Validate and create AuthorEvent
 * It validates:
 * - `product` against ProductSchema
 * - `event` and `payload` against Schema
 * Will throw error if it's invalid
 */


function createAuthorEvent(unsafeAuthorEvent, Schema, ProductSchema) {
  if (!(0, _utils.isObject)(unsafeAuthorEvent)) {
    throw new Error('AuthorEvent must be an object');
  }

  if (!unsafeAuthorEvent.event) {
    throw new Error('event is required');
  }

  if (!unsafeAuthorEvent.product) {
    throw new Error('product is required');
  }

  if (!unsafeAuthorEvent.payload) {
    throw new Error('payload is required');
  }

  if (!Object.keys(unsafeAuthorEvent.payload).length) {
    throw new Error('payload can not be empty');
  }

  var authorEvent = {
    event: unsafeAuthorEvent.event,
    product: unsafeAuthorEvent.product,
    payload: unsafeAuthorEvent.payload
  };
  (0, _schemaValidators.validateAuthorEvent)(authorEvent, Schema, ProductSchema);
  return authorEvent;
}
/**
 * Validate and create BaseEvent
 * It constructs BaseEvent by combining `authorEvent` and `globalPayload`
 * It validates:
 * - `product`, `event`, `payload` against the ProductSchema
 * Will throw error if it's invalid
 */


function createBaseEvent(authorEvent, MessageSchema) {
  var baseEvent = {
    event: authorEvent.event,
    product: authorEvent.product,
    payload: globalPayload
  }; // Validate BaseEvent against the schema

  (0, _schemaValidators.validateMessage)(baseEvent, MessageSchema, ['BaseEvent']);
  return baseEvent;
}
/**
 * Create ClientEvent
 * It creates ClientEvent by combining `baseEvent` and `authorPayload`
 * It filters payload based on a whitelist
 */


function createClientEvent(authorEvent, baseEvent, payloadWhitelist) {
  // Now we know client event is safe
  var clientEvent = {
    event: baseEvent.event,
    product: baseEvent.product,
    payload: _objectSpread(_objectSpread({}, globalPayload), authorEvent.payload)
  }; // whitelist payload

  if (payloadWhitelist.length) {
    var payloadFiltered = {};
    payloadWhitelist.forEach(function (payloadName) {
      payloadFiltered[payloadName] = clientEvent.payload[payloadName];
    });
    clientEvent.payload = payloadFiltered;
  } // clientEvent.event and client.pruduct is already snakecase


  clientEvent.payload = (0, _utils.transformObjectKeystoSnakeCase)(clientEvent.payload);
  return clientEvent;
}
/**
 * Try to create/send an event
 * It validates/send an event and catches any error in this process
 */


function trySendEvent(unsafeAuthorEvent, Schema, cfg) {
  var errorLogger = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : console.error;

  try {
    var _ref = cfg || {},
        _ref$payloadWhitelist = _ref.payloadWhitelist,
        payloadWhitelist = _ref$payloadWhitelist === void 0 ? [] : _ref$payloadWhitelist,
        _ref$apiOrigin = _ref.apiOrigin,
        apiOrigin = _ref$apiOrigin === void 0 ? '' : _ref$apiOrigin;

    var authorEvent = createAuthorEvent(unsafeAuthorEvent, Schema, BaseSchema.Product);
    var baseEvent = createBaseEvent(authorEvent, BaseSchema.ClientBase);
    var clientEvent = createClientEvent(authorEvent, baseEvent, payloadWhitelist);
    (0, _network.sendEvent)(clientEvent, (0, _network.getPixelApiUrl)(apiOrigin));
    return clientEvent;
  } catch (err) {
    errorLogger(err);
    return {};
  }
}
/**
 * A subLogger is curried with Schema, event, and product, which allows people to validate/send a specific event
 * without providing event and product information
 */


function createLogger(Schema, event, product) {
  var errorLogger = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : console.error;
  return function (payload, cfg) {
    return trySendEvent({
      event: event,
      product: product,
      payload: payload
    }, Schema, cfg, errorLogger);
  };
}

function processQueue(win, rootLogger) {
  var _win$s = win.s1;
  _win$s = _win$s === void 0 ? {} : _win$s;
  var _win$s$DPL = _win$s.DPL;
  _win$s$DPL = _win$s$DPL === void 0 ? {} : _win$s$DPL;
  var q = _win$s$DPL.q;

  if (Array.isArray(q)) {
    q.forEach(function (_ref2) {
      var _ref3 = (0, _slicedToArray2.default)(_ref2, 2),
          event = _ref3[0],
          cfg = _ref3[1];

      return rootLogger(event, cfg);
    });
  }
}