// Logs when the client route changes
import React from 'react';
import {GatsbyBrowser} from 'gatsby';
import {ExperimentContextProvider} from '../src/common/experiments/experimentContext';
import Layout from '../src/common/templates/Layout';

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({element}) => {
    return (
        <ExperimentContextProvider>
            <Layout>{element}</Layout>
        </ExperimentContextProvider>
    );
};
