"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.URL_PARAMS = exports.MAX_URL_LENGTH = exports.GA_LOCAL_STORAGE = exports.GA_COOKIE = exports.GA_URLS = exports.GTM = exports.DEV = exports.PROD_DOMAIN = exports.IFRAME_ID = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var IFRAME_ID = 's1-primary-ga-iframe';
exports.IFRAME_ID = IFRAME_ID;
var PROD_DOMAIN = 'https://s.flocdn.com';
exports.PROD_DOMAIN = PROD_DOMAIN;
var DEV = 'development';
exports.DEV = DEV;
var GTM = 'gtmjs';
exports.GTM = GTM;
var GA_URLS = (0, _defineProperty2.default)({}, GTM, 'https://www.googletagmanager.com/gtm.js');
exports.GA_URLS = GA_URLS;
var GA_COOKIE = '_ga'; // defined by GA

exports.GA_COOKIE = GA_COOKIE;
var GA_LOCAL_STORAGE = '_gaClientId'; // defined by S1

exports.GA_LOCAL_STORAGE = GA_LOCAL_STORAGE;
var MAX_URL_LENGTH = 2000;
exports.MAX_URL_LENGTH = MAX_URL_LENGTH;
var URL_PARAMS = {
  CLIENT_ID: 'clientId',
  LOCATION: 'location',
  PAGE: 'page',
  SCREEN: 'screen',
  // to manage iframe screen states (initial, cookie_check)
  SYNC_TYPE: 'syncType',
  TITLE: 'title'
};
exports.URL_PARAMS = URL_PARAMS;