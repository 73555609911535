module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[],"preset":{"colors":{"primary":"#007aff","secondary":"#000","background":"#fff","text":"#fff"},"fonts":{"body":"Roboto, sans-serif","heading":"Roboto, sans-serif"},"fontSizes":[12,14,16,20,24,32,48,64],"fontWeights":{"body":400,"heading":700,"bold":700},"lineHeights":{"body":1.5,"heading":1.125},"letterSpacings":{"body":"normal","caps":"0.2em"},"layout":{"main":{"height":"100%"}},"text":{"default":{"color":"text","fontFamily":"body"}},"styles":{"root":{"fontFamily":"body"}}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Roboto:ital,wght@0,100;0,300;0,400;0,500;1,100;1,300;1,400;1,500"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-ts-config/gatsby-browser.js'),
      options: {"plugins":[],"projectRoot":"/usr/src/app/www/frontend/packages/browser-extension-marketing-pages","configDir":".gatsby"},
    }]
