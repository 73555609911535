import {Logging, SOURCE} from '@s1/extensions';
import {extensions} from '@s1/dpl';
import {useExperiment} from '../experiments/experimentContext';
import {gtmId, gaId as gaMeasurementId} from '../constants';
import {getExtensionName} from '../ extensionMap';
import {useChannel} from '../hooks/useChannel';

const {
    ClickEvent,
    PageViewEvent,
    NewTabEvent,
    InstallEvent,
    UninstallEvent,
    SearchEvent,
    ExtensionsErrorEvent,
    clientId
} = Logging(SOURCE.WEB, {
    gtmId,
    gaMeasurementId
});

export const GAClientId = clientId;

export const useLogging = () => {
    const {experimentId} = useExperiment();
    const extName = typeof window === 'undefined' ? '' : getExtensionName(window.location.pathname);
    const channel = useChannel();

    return {
        ExtensionsErrorEvent: (params: extensions.ExtensionsErrorParams) => {
            return ExtensionsErrorEvent(params);
        },
        NewTabEvent: (logGA = true) => {
            return NewTabEvent({experimentId, extName}, logGA);
        },
        SearchEvent: (params: extensions.SearchParams, logGA = true) => {
            return SearchEvent(params, {experimentId, extName}, logGA);
        },
        PageViewEvent: (params: extensions.PageViewParams, logGA = true) => {
            return PageViewEvent(params, {experimentId, extName, channel}, logGA);
        },
        InstallEvent: (logGA = true) => {
            return InstallEvent({experimentId, extName}, logGA);
        },
        UninstallEvent: (logGA = true) => {
            return UninstallEvent({experimentId, extName}, logGA);
        },
        ClickEvent: (
            params: extensions.ClickParams,
            override: Partial<extensions.BaseExtensionParams> = {},
            logGA = true
        ) => {
            return ClickEvent(params, {experimentId, extName, ...override}, logGA);
        }
    };
};
