declare const GTM_ID: string;
declare const GA_ID: string;

/**
 * Everything in this file is defined in each site config and included using the define plugin
 * (see the createWebPackConfig hook in gatsby-node.ts)
 */

export const gtmId = GTM_ID;
export const gaId = GA_ID;
