import React, {FC, PropsWithChildren, useContext, useCallback} from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import {localStore} from '@s1/extensions';

const URL_PARAM = 'exp';
const store = localStore();

type ExperimentContext = {
    experimentId?: string;
};

export type ExperimentItem = {
    id: string;
    weight: number;
};
type ExperimentConfig = {
    path: string;
    bing: ExperimentItem[];
    default: ExperimentItem[];
};
type ExperimentsJSON = {
    experimentsJson: {
        experiments: ExperimentConfig[];
    };
};

export const ExperimentContext = React.createContext<ExperimentContext>({});

const weightedRandom = (items: ExperimentItem[]) => {
    if (items.length === 0) {
        return {
            id: 'default',
            weight: 100
        };
    }

    const r = Math.random();
    let sum = 0;
    let selection = items[0];
    for (let i = 0; i < items.length; i += 1) {
        const {weight} = items[i];
        sum += weight / 100;
        if (r < sum) {
            selection = items[i];
            break;
        }
    }
    return selection;
};

const getSourceForExperiment = () => {
    if (typeof window === 'undefined') {
        return 'default';
    }
    const source = new URLSearchParams(window.location.search).get('source');
    if (source === 'bing-sem') {
        return 'bing';
    }
    return 'default';
};

const getExperimentsFromPath = () => {
    if (typeof window === 'undefined') {
        return 'default';
    }
    const path = window.location.pathname;
    return path === '' ? 'default' : path;
};

export const ExperimentContextProvider: FC<PropsWithChildren<{}>> = ({children}) => {
    const source = getSourceForExperiment();
    const path = getExperimentsFromPath();
    const {
        experimentsJson: {experiments: experimentConfig}
    }: ExperimentsJSON = useStaticQuery(graphql`
        {
            experimentsJson {
                experiments {
                    bing {
                        id
                        weight
                    }
                    default {
                        id
                        weight
                    }
                    path
                }
            }
        }
    `);
    // pull experiments using path field
    const experimentConf: ExperimentConfig | undefined = experimentConfig.find((experiment) => {
        const regexPath = new RegExp(path, 'g');
        return regexPath.exec(experiment.path);
    });

    let experiments: ExperimentItem[] = [];

    if (typeof experimentConf !== 'undefined') {
        experiments = experimentConf[source];
    }

    const getExperimentFromURL = () => {
        return new URLSearchParams(window.location.search).get(URL_PARAM);
    };

    const getExperimentFromConfig = (data: ExperimentItem[]) => {
        const {id} = weightedRandom(data);
        return id;
    };

    const getExperimentId = useCallback(() => {
        const isValidExperiment = (id: string) => {
            const ids = experiments.map((experiment: {id: string}) => experiment.id);
            return ids.includes(id);
        };

        const urlId = typeof window !== 'undefined' ? getExperimentFromURL() : null;
        if (urlId !== null && isValidExperiment(urlId)) {
            return urlId;
        }

        const {experimentId = ''} = store.get();

        if (experimentId !== '') {
            return experimentId;
        }

        return getExperimentFromConfig(experiments);
    }, [experiments]);

    const experimentId = getExperimentId();
    store.set({experimentId});
    return <ExperimentContext.Provider value={{experimentId}}>{children}</ExperimentContext.Provider>;
};

export const useExperiment = () => {
    const {experimentId} = useContext(ExperimentContext);
    if (experimentId === undefined) {
        throw Error("Couldn't find an experiment context, did you add the experiment context provider?");
    }
    return {experimentId};
};
