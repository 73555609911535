"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateParams = validateParams;
exports.createGaLoader = createGaLoader;

var _constants = require("../constants");

var _utils = require("../utils");

var _cookies = require("../cookies");

var _debug = _interopRequireDefault(require("../debug"));

function errorMsg(msg) {
  return "DPL.loadGa failed: ".concat(msg);
}

function validateParams(_ref) {
  var gaMeasurementId = _ref.gaMeasurementId,
      gtmId = _ref.gtmId,
      maxTimeout = _ref.maxTimeout;
  if (!gaMeasurementId) throw new Error(errorMsg('gaMeasurementId is required.'));
  if (typeof gaMeasurementId !== 'string') throw new Error(errorMsg('gaMeasurementId should be a string.'));
  if (!gtmId) throw new Error(errorMsg('gtmId is required.'));
  if (typeof gtmId !== 'string') throw new Error(errorMsg('gtmId should be a string.'));
  if (!(0, _utils.isInteger)(maxTimeout)) throw new Error(errorMsg('maxTimeout should be an ingeter.'));
} // TODO:: Turn this into a singleton function loadGa


function createGaLoader(getPrimaryClientId) {
  return function (_ref2, onPrimaryClientIdReady) {
    var _ref2$gaMeasurementId = _ref2.gaMeasurementId,
        gaMeasurementId = _ref2$gaMeasurementId === void 0 ? '' : _ref2$gaMeasurementId,
        _ref2$gtmId = _ref2.gtmId,
        gtmId = _ref2$gtmId === void 0 ? '' : _ref2$gtmId,
        _ref2$maxTimeout = _ref2.maxTimeout,
        maxTimeout = _ref2$maxTimeout === void 0 ? 2000 : _ref2$maxTimeout;
    if (window.ga || window.gtag) throw new Error(errorMsg('Google Analytics scripts already loaded.'));
    validateParams({
      gaMeasurementId: gaMeasurementId,
      gtmId: gtmId,
      maxTimeout: maxTimeout
    });

    var _ref3 = window || {},
        localStorage = _ref3.localStorage;

    var verifiedClientId = localStorage ? localStorage.getItem(_constants.GA_LOCAL_STORAGE) || '' : '';
    var gtmUrl = "".concat(_constants.GA_URLS[_constants.GTM], "?id=").concat(gtmId); // allows early access to window.ga

    (0, _utils.setupGaQueue)(); // allows access to window.dataLayer

    (0, _utils.setupDataLayer)();

    if (verifiedClientId) {
      (0, _debug.default)('ga: use verifiedClientId', verifiedClientId);
      window.ga('create', gaMeasurementId, {
        clientId: verifiedClientId
      });
      (0, _utils.loadScript)(gtmUrl);
      window.dataLayer.unshift({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js'
      });
      if (onPrimaryClientIdReady) onPrimaryClientIdReady(verifiedClientId);
    } else {
      var currentClientId = (0, _cookies.getClientId)(_constants.GA_COOKIE);
      getPrimaryClientId(function (data) {
        var clientId = currentClientId || '';

        var _ref4 = data || {},
            _ref4$primaryClientId = _ref4.primaryClientId,
            primaryClientId = _ref4$primaryClientId === void 0 ? '' : _ref4$primaryClientId;

        if (primaryClientId) {
          clientId = primaryClientId;
          (0, _debug.default)("ga: received ".concat(clientId, " from primary sync"));
        } else {
          (0, _debug.default)("ga: unable to determine clientId from primary sync: using(".concat(clientId, ")"));
        }

        if (clientId && localStorage) {
          localStorage.setItem(_constants.GA_LOCAL_STORAGE, clientId);
          (0, _debug.default)("ga: stored ".concat(clientId, " to skip future sync"));
        }

        var createConfig = clientId ? {
          clientId: clientId
        } : 'auto';
        window.ga('create', gaMeasurementId, createConfig);
        window.dataLayer.unshift({
          'gtm.start': new Date().getTime(),
          event: 'gtm.js'
        });
        (0, _utils.loadScript)(gtmUrl);
        if (onPrimaryClientIdReady) onPrimaryClientIdReady(clientId);
      }, maxTimeout, currentClientId);
    }
  };
}