"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.loadScript = exports.setupDataLayer = exports.setupGaQueue = exports.transformObjectKeystoSnakeCase = exports.hasProperty = exports.isInteger = exports.isFunction = exports.isObject = exports.getUniqueId = exports.uuidv4 = exports.castToBoolean = void 0;

var _snakeCase = require("snake-case");

var castToBoolean = function castToBoolean(val) {
  return !['false', '0'].some(function (x) {
    return x === val.trim().toLowerCase();
  });
};
/**
 * An RFC4122 version 4 compliant uuid solution
 * https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
 */


exports.castToBoolean = castToBoolean;

var uuidv4 = function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    // eslint-disable-next-line no-bitwise
    var r = Math.random() * 16 | 0; // eslint-disable-next-line no-bitwise

    var v = c === 'x' ? r : r & 0x3 | 0x8;
    return v.toString(16);
  });
};

exports.uuidv4 = uuidv4;

var getUniqueId = function getUniqueId() {
  return "".concat(Date.now(), "-").concat(uuidv4());
}; // eslint-disable-next-line flowtype/no-weak-types


exports.getUniqueId = getUniqueId;

var isObject = function isObject(obj) {
  return Object.prototype.toString.call(obj) === '[object Object]';
}; // eslint-disable-next-line flowtype/no-weak-types


exports.isObject = isObject;

var isFunction = function isFunction(obj) {
  return Object.prototype.toString.call(obj) === '[object Function]';
};

exports.isFunction = isFunction;

var isInteger = function isInteger(value) {
  if (Number.isInteger) {
    return Number.isInteger(value);
  } // Number.isFinite is not available on IE so we have to use global.isFinite
  // eslint-disable-next-line no-restricted-properties


  return typeof value === 'number' && global.isFinite(value) && Math.floor(value) === value;
}; // eslint-disable-next-line flowtype/no-weak-types


exports.isInteger = isInteger;

var hasProperty = function hasProperty(obj, propertyName) {
  return Object.prototype.hasOwnProperty.call(obj, propertyName);
}; // eslint-disable-next-line flowtype/no-weak-types


exports.hasProperty = hasProperty;

var transformObjectKeystoSnakeCase = function transformObjectKeystoSnakeCase(obj) {
  var newObj = {};
  Object.keys(obj).forEach(function (key) {
    var val = obj[key];

    if (!isObject(val)) {
      newObj[(0, _snakeCase.snakeCase)(key)] = val;
    } else {
      newObj[(0, _snakeCase.snakeCase)(key)] = transformObjectKeystoSnakeCase(val);
    }

    return newObj;
  }, {});
  return newObj;
};

exports.transformObjectKeystoSnakeCase = transformObjectKeystoSnakeCase;

var setupGaQueue = function setupGaQueue() {
  function gaQueue() {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    (window.ga.q = window.ga.q || []).push(args);
  }

  window.GoogleAnalyticsObject = 'ga';
  window.ga = window.ga || gaQueue;
  window.ga.l = 1 * new Date();
};

exports.setupGaQueue = setupGaQueue;

var setupDataLayer = function setupDataLayer() {
  window.dataLayer = window.dataLayer || [];
};

exports.setupDataLayer = setupDataLayer;

var loadScript = function loadScript(src, cb) {
  var el = document.createElement('script');
  el.async = true;
  el.src = src;
  if (cb) el.addEventListener('load', cb);
  var parentNode = document.head || document.body;
  if (parentNode) parentNode.appendChild(el);
};

exports.loadScript = loadScript;