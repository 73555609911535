"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCookiesByName = getCookiesByName;
exports.getCookieByNameAndTopDomain = getCookieByNameAndTopDomain;
exports.getClientId = getClientId;

/**
 * Return array of cookie values by name
 * @param name : string // name of cookie
 * @return array // array of values by cookie name
 */
function getCookiesByName(name) {
  if (!document || !document.cookie) {
    return [];
  }

  var value = "; ".concat(document.cookie);
  var parts = value.split("; ".concat(name, "="));
  parts.shift();
  var values = parts.map(function (part) {
    return part.split(';').shift();
  });
  return values;
}
/**
 * Finds all values of a cookie name, attempts to determine which cookie is associated to highest order domain and returns it
 * @param name string // name of cookie, multiple cookies with same name can exist due to subdomain wildcards
 * @return string // value of the cookie prefering higher-order domain or current domain
 */


function getCookieByNameAndTopDomain(name) {
  var values = getCookiesByName(name);

  if (!values.length) {
    return '';
  }

  if (values.length === 1) {
    return values[0];
  } // if multiple cookies exist then user has been to domain + sub-domains.
  // grab the cookie closest to the top-level domain


  var token = '$domainCookieToken';
  document.cookie = "".concat(name, "=").concat(token, ";domain=.").concat(window.location.hostname, ";path=/;SameSite=Strict"); // temp replace with a token

  var valuesTokenized = getCookiesByName(name);
  var currentDomainCookieValue = '';

  for (var i = 0; i < values.length; i += 1) {
    var isCurrentDomain = false; // get the first cookie that ISNT on this domain (implies higher domain)

    for (var ii = 0; ii < valuesTokenized.length; ii += 1) {
      if (valuesTokenized[ii] === values[i]) {
        isCurrentDomain = true;
        break;
      }
    }

    if (!isCurrentDomain) {
      currentDomainCookieValue = values[i];
      break;
    }
  } // const domainSpecificValue = values.find(value => !valuesTokenized.includes(value)) || '';


  document.cookie = "".concat(name, "=").concat(currentDomainCookieValue, ";domain=.").concat(window.location.hostname, ";path=/;SameSite=Strict"); // replace with correct cookie
  // return the value NOT on this domain (implies higher order domain)

  var topDomainCookieValue = '';

  for (var _i = 0; _i < valuesTokenized.length; _i += 1) {
    if (valuesTokenized[_i] !== token) {
      topDomainCookieValue = valuesTokenized[_i];
      break;
    }
  }

  return topDomainCookieValue;
}
/**
 *
 */


function getClientId(clientIdCookieName) {
  var gaClientId = getCookieByNameAndTopDomain(clientIdCookieName);
  var gaRegEx = /.*GA.\..\.(.*)/g;
  var gaClientIdMatch = gaRegEx.exec(gaClientId) || [];
  return gaClientIdMatch[1] || gaClientId;
}