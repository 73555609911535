"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPixelApiUrl = getPixelApiUrl;
exports.loadImage = loadImage;
exports.loadAjax = loadAjax;
exports.loadSendBeacon = loadSendBeacon;
exports.sendEvent = sendEvent;
exports.DEFAULT_ORIGIN = void 0;

var _debug = _interopRequireDefault(require("../debug"));

var pendingEvents = [];
var isThrottling = false;
var DEFAULT_ORIGIN = 'https://soflopxl.com';
exports.DEFAULT_ORIGIN = DEFAULT_ORIGIN;
var INTERVAL = 250; // This size is due to IE11 has a 2048 characters url length
// https://support.microsoft.com/en-us/help/208427/maximum-url-length-is-2-083-characters-in-internet-explorer

var MAX_PAYLOAD_SIZE = 2000;

function getPixelApiUrl() {
  var apiOrigin = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  // TODO:: Don't see the need to overide API origin
  return "".concat(apiOrigin || DEFAULT_ORIGIN, "/dplpxs");
}

function loadImage(url, payload) {
  try {
    var parametersString = "om_event=".concat(encodeURIComponent(payload));

    if (parametersString.length <= MAX_PAYLOAD_SIZE) {
      var img = document.createElement('img');
      img.width = 1;
      img.height = 1;
      img.src = "".concat(url, "?").concat(parametersString);
      return true;
    }

    (0, _debug.default)('Can not create image pixel with url longer than 2000 chars');
    return false;
  } catch (err) {
    (0, _debug.default)(err);
    return false;
  }
}

function loadAjax(url, payload) {
  try {
    var xhr = new XMLHttpRequest();
    if (!('withCredentials' in xhr)) return false;
    xhr.open('POST', url, true);
    xhr.withCredentials = true;
    xhr.setRequestHeader('Content-Type', 'text/plain');
    xhr.send(payload);
    return true;
  } catch (err) {
    (0, _debug.default)(err);
    return false;
  }
}

function loadSendBeacon(url, payload) {
  try {
    global.navigator.sendBeacon(url, payload);
    return true;
  } catch (err) {
    (0, _debug.default)(err);
    return false;
  }
}

function callPixel() {
  if (!pendingEvents.length) return;
  var apiUrl = pendingEvents[0].apiUrl;
  var eventsArray = pendingEvents.map(function (_ref) {
    var event = _ref.event;
    return event;
  });
  var payload = JSON.stringify(eventsArray);
  pendingEvents = [];
  (0, _debug.default)('trying loadSendBeacon to send: ', eventsArray);

  if (!loadSendBeacon(apiUrl, payload)) {
    (0, _debug.default)('trying loadAjax to send: ', eventsArray);

    if (!loadAjax(apiUrl, payload)) {
      (0, _debug.default)('trying loadImage to send: ', eventsArray);

      if (!loadImage(apiUrl, payload)) {
        (0, _debug.default)('Failed sending client events.');
        throw new Error('Failed sending client events.');
      }
    }
  }
}

function throttledCallPixel(interval) {
  // always  leading
  callPixel();
  isThrottling = true; // this queues up following sendEvent calls during `interval` so we can batch them

  global.setTimeout(function () {
    callPixel();
    isThrottling = false;
  }, interval);
}

function sendEvent(clientEvent, apiUrl) {
  pendingEvents.push({
    apiUrl: apiUrl,
    event: clientEvent
  });

  if (!isThrottling) {
    throttledCallPixel(INTERVAL);
  } // for browsers, flush the pendingEvents before leaving the page
  // this prevent losing the trailing events


  if (global.addEventListener) {
    global.addEventListener('beforeUnload', function () {
      callPixel();
    });
  } // TODO:: For Native Apps, excute tracking when app backgrounds or closes

}