"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.isDebugEnabled = void 0;

var _hashParams = _interopRequireDefault(require("./hashParams"));

var isDebugEnabled = !!_hashParams.default.debugEnabled;
exports.isDebugEnabled = isDebugEnabled;
var debug = isDebugEnabled && window && window.console && typeof console !== 'undefined' ? window.console.log.bind(window.console, 'DPL:') : function () {};
var _default = debug;
exports.default = _default;