"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.loadIframe = loadIframe;
exports.onMessage = onMessage;
exports.createPrimaryClientIdGetter = createPrimaryClientIdGetter;
exports.state = void 0;

var _constants = require("../constants");

var _utils = require("../utils");

var _debug = _interopRequireWildcard(require("../debug"));

var state = {
  primaryGaIframe: null,
  cb: null,
  timer: null
};
exports.state = state;

function loadIframe(version, env) {
  var clientId = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  var _window = window,
      document = _window.document,
      location = _window.location;
  var iframe = document.createElement('iframe');
  var iframeSrcRoot = env === _constants.DEV ? '/@s1/iframe.html' : "".concat(_constants.PROD_DOMAIN, "/%40s1/dpl/").concat(version, "/iframe.html");
  var iframeSrc = "".concat(iframeSrcRoot, "#s1&debug=").concat(String(_debug.isDebugEnabled), "&clientId=").concat(clientId, "&page=").concat(location.pathname, "&location=").concat(encodeURIComponent(location.href), "=&title=").concat(encodeURIComponent(document.title)).substring(0, _constants.MAX_URL_LENGTH);
  iframe.setAttribute('id', _constants.IFRAME_ID);
  iframe.setAttribute('src', iframeSrc);
  iframe.setAttribute('style', 'display:none;');
  document.body.appendChild(iframe);
  return iframe;
} // listen for message from the iframe and flush the pending callbacks


function onMessage(_ref) {
  var data = _ref.data;

  if ((0, _utils.isObject)(data) && Object.prototype.hasOwnProperty.call(data, 'primaryClientId')) {
    window.removeEventListener('message', onMessage);
    global.clearTimeout(state.timer);

    if (state.cb != null) {
      state.cb(data);
    }
  }
} // TODO:: Turn this into a singleton function getPrimaryClientId
// create iframe and listen for post messages from that iframe


function createPrimaryClientIdGetter(version, env) {
  return function (cb) {
    var maxTimeout = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2000;
    var clientId = arguments.length > 2 ? arguments[2] : undefined;
    state.cb = cb;
    window.addEventListener('message', onMessage, false);
    state.timer = global.setTimeout(function () {
      (0, _debug.default)("warning: maxTimeout ".concat(maxTimeout, " reached"));
      window.removeEventListener('message', onMessage);
      cb(null);
    }, maxTimeout);

    var readyLoadIframe = function readyLoadIframe() {
      state.primaryGaIframe = loadIframe(version, env, clientId);
    };

    var _window2 = window,
        _window2$document = _window2.document,
        document = _window2$document === void 0 ? {} : _window2$document;

    if (document.readyState === 'complete' || document.readyState !== 'loading' && !document.documentElement.doScroll) {
      readyLoadIframe();
    } else {
      document.addEventListener('DOMContentLoaded', readyLoadIframe);
    }
  };
}