"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.typeError = typeError;
exports.validateMessage = validateMessage;
exports.validateAuthorEvent = validateAuthorEvent;

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _utils = require("../utils");

/**
 * Returns a message to describe the type error
 */
function typeError(varName, type) {
  return "".concat(varName, " should be ").concat(type);
} // eslint-disable-next-line flowtype/no-weak-types


function validateProduct(enums, val) {
  // keyword `enum` is reserved
  if (!(0, _utils.hasProperty)(enums, val)) {
    throw new Error("Product ".concat(val, " is missing in the schema"));
  }
} // eslint-disable-next-line flowtype/no-weak-types


function validateScalarType(message, fieldName, type, path) {
  var val = message[fieldName];
  var pathStr = path.join('.');

  switch (type) {
    case 'int32':
    case 'uint32':
    case 'sint32':
    case 'fixed32':
    case 'sfixed32':
      if (!(0, _utils.isInteger)(val)) {
        throw new Error(typeError(pathStr, 'integer'));
      }

      break;

    case 'float':
    case 'double':
      if (typeof val !== 'number') {
        throw new Error(typeError(pathStr, type));
      } else if ((0, _utils.isInteger)(val)) {
        throw new Error(typeError(pathStr, type));
      }

      break;

    case 'bool':
      if (typeof val !== 'boolean') {
        throw new Error(typeError(pathStr, type));
      }

      break;

    case 'string':
      if (typeof val !== 'string') {
        throw new Error(typeError(pathStr, type));
      }

      break;

    default:
      /**
       * TODO:: Should we support these types?
       * int64, uint64, sint64, fixed64, sfixed64, bytes
       */
      throw new Error("We currently does not support validation against type ".concat(type, " for field ").concat(pathStr));
  }
}

function isScalarType(type) {
  return /^(double|float|int32|int64|uint32|uint64|sint32|sint64|fixed32|fixed64|sfixed32|sfixed64|bool|string|bytes)$/.test(type);
}

function validateMessage(message, MessageSchema) {
  var path = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  // TODO:: Should we support required fields?
  var schemaFields = MessageSchema.fields,
      nestedSchema = MessageSchema.nested;
  Object.keys(message).forEach(function (fieldName) {
    var curPath = [].concat((0, _toConsumableArray2.default)(path), [fieldName]);

    if (schemaFields[fieldName] === undefined) {
      throw new Error("Field ".concat(curPath.join('.'), " is missing in the schema"));
    } // it's either a scalar type or a nested type
    // protobuf won't generate invalid types


    var type = schemaFields[fieldName].type;

    if (isScalarType(type)) {
      validateScalarType(message, fieldName, type, curPath);
    } else if (nestedSchema && nestedSchema[type] && message[fieldName]) {
      validateMessage(message[fieldName], nestedSchema[type], curPath);
    }
  });
}

function validateAuthorEvent(authorEvent, Schema, ProductSchema) {
  if (authorEvent.product === undefined) {
    throw new Error('product is required');
  }

  if (typeof authorEvent.product !== 'string') {
    throw new Error(typeError('product', 'string'));
  }

  if (authorEvent.event === undefined) {
    throw new Error('event is required');
  }

  if (typeof authorEvent.event !== 'string') {
    throw new Error(typeError('event', 'string'));
  }

  if (!(0, _utils.isObject)(ProductSchema)) {
    throw new Error('invalid ProductSchema');
  }

  var messageName = authorEvent.event,
      product = authorEvent.product;
  Object.keys(authorEvent).forEach(function (field) {
    switch (field) {
      case 'product':
        {
          if (!ProductSchema) {
            throw new Error('Found field product in message but a productSchema is missing');
          }

          validateProduct(ProductSchema.values, product);
          break;
        }

      case 'event':
        {
          if (Schema[messageName] === undefined) {
            throw new Error("Event ".concat(messageName, " is missing in the schema"));
          }

          break;
        }

      case 'payload':
        {
          var payload = authorEvent[field];

          if (payload !== undefined) {
            if (!(0, _utils.isObject)(payload)) {
              throw new Error(typeError('payload', 'object'));
            }

            validateMessage(payload, Schema[messageName], [messageName, 'payload']);
          }

          break;
        }
      // fields doesn't exist in the schema

      default:
        throw new Error("Invalid field ".concat(field));
    }
  });
}