import {ExtensionName} from '@s1/extensions';

const extensionMap = new Map<ExtensionName, string[]>([
    [ExtensionName.MapquestDefaultSearch, ['directions-search', 'directions-search-offer', 'success']],
    [ExtensionName.MapquestNewTab, ['', 'newtab', 'new-tab', 'directions-newtab', 'directions-newtab-offer']]
]);

export const getExtensionName = (path: string) => {
    const [, rootPath = ''] = path.split('/');

    const [[extensionName = ''] = []] = Array.from(extensionMap).filter(([, paths]) => paths.includes(rootPath));

    return extensionName;
};
