"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getParameter = getParameter;
exports.isParamEnabled = isParamEnabled;
exports.default = void 0;

var _utils = require("./utils");

function getParameter(parameterName) {
  var _ref = global.location || {},
      _ref$hash = _ref.hash,
      hash = _ref$hash === void 0 ? '' : _ref$hash;

  var name = parameterName.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp("[#s1&]".concat(name, "=([^&#]*)"), 'i');
  var results = regex.exec(hash);

  if (!results) {
    return '';
  }

  var decodedUri = ''; // decodeURIComponent throws an error if it doesn't succeed, we want to
  // catch this error gracefully and return an empty string

  try {
    decodedUri = decodeURIComponent(results[1].replace(/\+/g, ' '));
  } catch (err) {
    console.error(err); // eslint-disable-line no-console

    return '';
  }

  return decodedUri;
}

function isParamEnabled(paramName) {
  var param = getParameter(paramName);
  if (!param) return null;
  return (0, _utils.castToBoolean)("".concat(param));
}

var hashParams = {
  debugEnabled: isParamEnabled('debug')
};
var _default = hashParams;
exports.default = _default;